import { isEqual, get, omit } from 'lodash'
import {
  CHANGE_PLAN_MODAL_WINDOW_STATE_CHANGE,
  CHANGE_PLAN_MODAL_WINDOW_STATE_CHECKOUT,
  TIMEOUT_FOR_CREATING_QUOTATION,
  TYPE_CLOUD
} from '@/constants/app'
import { downloadFile, uploadFileToBrowser } from '@/utils/download'
import { getClientIdForRequest, requests } from '@/utils/requests'
import {
  productChangePlanGetPackagesPricesRequest,
  downloadContractFileRequest,
  productChangePlanCreateQuotationRequest,
  downloadContractPackageRequest,
  productChangePlanGetOrderSummaryRequest
} from '@/utils/requestsTemplates'
import getApiErrorMessage from '@/utils/getApiErrorMessage'
const today = new Date().toISOString().slice(0, 10)

let updateOrderSummaryTimeout
const UPDATE_ORDER_SUMMARY_DELAY = 2000

const getSummaryPayload = (requestPayload, guid) => omit({ ...requestPayload, guid }, 'subscriptionExtensionInYears')

export default {
  getOrderPrice: ({ commit, rootState }, { clientId, guid, ...requestPayload }) => {
    const clientIdForRequest = getClientIdForRequest(clientId)
    commit('setLoadingOrderSummary', true)
    commit('setLoadingPlugins', true)
    const contracts = get(rootState.contract, `clients[${clientId}]`, [])
    const { product } = (contracts.find(c => c.guid === guid) || { product: null })

    if (updateOrderSummaryTimeout) {
      clearTimeout(updateOrderSummaryTimeout)
    }

    updateOrderSummaryTimeout = setTimeout(async function () {
      // stop executing action if request payload is equal to product values
      // or if type of product is Cloud
      if (isEqual(getSummaryPayload(requestPayload, guid), product) && product.type !== TYPE_CLOUD) {
        const response = await requests.get(
          productChangePlanGetPackagesPricesRequest(
            clientIdForRequest,
            guid
          )
        )

        commit('setAvailablePlugins', response.data)
        commit('order/clearOrder', {}, { root: true })
        commit('setLoadingOrderSummary', false)
        commit('setLoadingPlugins', false)
        return
      }

      try {
        const response = await requests.post(
          productChangePlanGetOrderSummaryRequest(clientIdForRequest, guid),
          { body: requestPayload }
        )

        commit('order/setOrder', { summary: response.data.orderSummary }, { root: true })
        commit('setAvailablePlugins', response.data.availablePlugins)
      } catch (err) {
        commit('order/clearOrder', {}, { root: true })
        const errorKey = getApiErrorMessage(err)
        // eslint-disable-next-line no-console
        console.error(errorKey)
      } finally {
        commit('setLoadingOrderSummary', false)
        commit('setLoadingPlugins', false)
      }

    }, UPDATE_ORDER_SUMMARY_DELAY)
  },
  getAvailablePlugins: async (
    { commit },
    { clientId, contractId }
  ) => {
    commit('setLoadingPlugins', true)
    const clientIdForRequest = getClientIdForRequest(clientId)

    try {
      const response = await requests.get(
        productChangePlanGetPackagesPricesRequest(
          clientIdForRequest,
          contractId
        )
      )

      commit('setAvailablePlugins', response.data)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }

    commit('setLoadingPlugins', false)
  },
  createQuotation: async (
    { commit },
    { clientId, contractId, ...requestPayload }
  ) => {
    const creationTimeout = setTimeout(() => {
      commit(
        'setChangePlanModalWindowState',
        CHANGE_PLAN_MODAL_WINDOW_STATE_CHECKOUT
      )
    }, TIMEOUT_FOR_CREATING_QUOTATION)
    const clientIdForRequest = getClientIdForRequest(clientId)

    const response = await requests.post(
      productChangePlanCreateQuotationRequest(
        clientIdForRequest,
        contractId
      ),
      { body: requestPayload }
    )
    clearTimeout(creationTimeout)

    if (response) {
      commit('order/setOrder', response.data, { root: true })
      commit(
        'setChangePlanModalWindowState',
        CHANGE_PLAN_MODAL_WINDOW_STATE_CHECKOUT
      )
    } else {
      commit('pendingRequests/setServiceUnavailableError', true, { root: true })
      commit(
        'setChangePlanModalWindowState',
        CHANGE_PLAN_MODAL_WINDOW_STATE_CHANGE
      )
    }
  },
  download: async (
    { dispatch, commit, state },
    { clientId, contractId, documentName }
  ) => {
    const data = await downloadFile(
      downloadContractFileRequest(clientId, contractId, documentName)
    )
    uploadFileToBrowser(data, `${documentName}_${contractId}_${today}`, 'pdf')
  },
  downloadPackage: async (
    { dispatch, commit, state },
    { clientId, contractId, packageType }
  ) => {
    if (state.downloadingPackage) {
      return
    }

    commit('setDownloadingPackage', { clientId, contractId })

    const clientIdForRequest = getClientIdForRequest(clientId)

    try {
      const data = await downloadFile(
        downloadContractPackageRequest(clientIdForRequest, contractId, packageType)
      )

      uploadFileToBrowser(data, `installation-package_${packageType}_${contractId}_${today}`, 'zip')
    } catch (e) {
      console.error(e)
    } finally {
      commit('setDownloadingPackage', null)
    }
  }
}
