<template>
  <div>
    <div
      v-if="loadingOrderSummary"
      :class="loadingClasses"
    >
      <v-progress-circular
        indeterminate
        data-cy="order-summary-progress-circular"
      />
    </div>
    <div
      v-else-if="!loadingOrderSummary && !order"
      class="text-center"
    >
      <p
        v-t="'productDetails.nothingChanged'"
        class="mb-0"
      />
    </div>

    <div v-else-if="summaryVisible">
      <material-order-summary-table :order-summary="order.summary" />
      <material-product-details-change-plan-submit-button :form-data="formData" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    formData: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('productDetails', ['loadingOrderSummary']),
    ...mapState('order', ['order']),
    summaryVisible () {
      return this.order && this.order.summary && this.order.summary.plugins
    },
    loadingClasses () {
      return `mt-4 text-center${this.summaryVisible ? ' mb-4' : ''}`
    }
  }
}
</script>
